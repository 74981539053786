<template>
    <v-app>

        <core-app-bar/>
        <!--
        Side Drawer
        <core-drawer />
        -->
        <v-main>
            <core-alert/>
            <router-view/>
        </v-main>
        <core-footer/>

    </v-app>
</template>

<script>
    export default {
        name: 'App',

        components: {
            CoreAlert: () => import('./components/Core/Alert'),
            CoreFooter: () => import('./components/Core/AppBar'),
            CoreAppBar: () => import('./components/Core/Footer'),
            //CoreDrawer: () => import('./components/Core/Drawer'),
        },
        data: () => ({}),
    };
</script>
