import Vue from 'vue';
import Vuetify from 'vuetify';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueCookies from 'vue-cookies';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css'
import VueJwtDecode from 'vue-jwt-decode';


Vue.use(Vuetify);
Vue.use(VueAxios, axios);
Vue.use(VueCookies);
Vue.use(VueJwtDecode)

export default new Vuetify({
    theme: {
        options: { customProperties: true },
        themes: {
            light: {
                primary: '#81c784',
                secondary: '#43a047',
                accent: '#80d779',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
                background: '#EEEEEE',
                "background--lighten-1": '#c0bfbf',
                "background--lighten-2": '#e5e5e5',
            },
            dark: {
                primary: '#2e7d32',
                secondary: '#81c784',
                accent: '#042a00',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
                background: '#121212',
                "background--lighten-1": '#00000c',
                "background--lighten-2": '#333333',},
        },
    },
});

