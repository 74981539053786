import {sessionHandler} from "@/request/sessionHandler";
import {getLawyerByUsernameURL, requestMode} from "@/configBuilder";
import {showAlert} from "@/utils/alertHandler";
import i18n from "@/plugins/i18n";
import reactiveStorage from "@/plugins/reactiveStorage";
import Vue from "vue";


export async function getAllLawyers(){
    //  if Session isValid
    await sessionHandler();

    await Vue.axios
        .get(
            getLawyerByUsernameURL(reactiveStorage.user.Username)
            , {
                mode: requestMode(),
                headers: {
                    'Accept': 'application/json',
                    'authorization': reactiveStorage.app.AuthenticationType + ' ' + Vue.$cookies.get('access_token'),
                },
            }
        )
        .then((response) => {
            if (response.status === 200) {
                // console.log(response.data[0])
                reactiveStorage.user.permissions.insurance.advocardBusinessDataType = response.data[0].advocardBusinessPermissions
                reactiveStorage.user.permissions.insurance.advocardInternationalDataType = response.data[0].advocardInternationalPermissions
                reactiveStorage.user.permissions.insurance.advocardPrivateDataType = response.data[0].advocardPrivatePermissions
                reactiveStorage.user.permissions.insurance.aragDataType = response.data[0].aragPermissions
                reactiveStorage.user.permissions.insurance.rolandDataType = response.data[0].rolandPermissions
                reactiveStorage.user.permissions.MPU = response.data[0].mpuPermissions
            }
        })
        .catch(() => {
            showAlert(i18n.t('warning.unauthorised'), "error");
        })
}

export const permissionConst = [
    { name: "Advocard Private", value: "advocardPrivatePermissions",
        childPermissions: [
            { name: "Telefonberatung", value: "advocardPrivatePermissions/Telefonberatung" },
            { name: "Dokumentencheck", value: "advocardPrivatePermissions/Dokumentencheck" },
            { name: "Mediation", value: "advocardPrivatePermissions/Mediation" },
            { name: "VermittlungMediation", value: "advocardPrivatePermissions/VermittlungMediation" },
            { name: "VermittlungCoaching", value: "advocardPrivatePermissions/VermittlungCoaching" },
            { name: "Coaching", value: "advocardPrivatePermissions/Coaching" },
            { name: "VermittlungCoaching", value: "advocardPrivatePermissions/VermittlungFachberatung" },
            { name: "Coaching", value: "advocardPrivatePermissions/Fachberatung" },
        ] },
    { name: "Advocard Business", value: "advocardBusinessPermissions", childPermissions: [ { name: "Telefonberatung", value: "advocardBusinessPermissions/Telefonberatung" } ] },
    { name: "Advocard International", value: "advocardInternationalPermissions", childPermissions: [ { name: "Telefonberatung", value: "advocardInternationalPermissions/Telefonberatung" }, { name: "Dokumentencheck", value: "advocardInternationalPermissions/Dokumentencheck" }, { name: "Mediation", value: "advocardInternationalPermissions/Mediation" }, { name: "VermittlungMediation", value: "advocardInternationalPermissions/VermittlungMediation" }, { name: "VermittlungCoaching", value: "advocardInternationalPermissions/VermittlungCoaching" }, { name: "Coaching", value: "advocardInternationalPermissions/Coaching" } ] },
    { name: "Arag", value: "aragPermissions", childPermissions: [
            { name: "Dokumentenprüfung", value: "aragPermissions/Dokumentenpruefung" },
            { name: "OnlineBeratung", value: "aragPermissions/OnlineBeratung" },
            { name: "Telefonberatung", value: "aragPermissions/Telefonberatung" },
            { name: "Vertragscheck", value: "aragPermissions/Vertragscheck" },
            { name: "Erstberatung Versicherungsprüfung", value: "aragPermissions/ErstberatungVersicherungspruefung" },
            { name: "Erstberatung Versicherungsprüfung Vermittlung", value: "aragPermissions/ErstberatungVersicherungspruefungVermittlung" },
            { name: "Manager Rechtsschutz", value: "aragPermissions/ManagerRechtsschutz" },
            { name: "Strafrechtshotline", value: "aragPermissions/Strafrechtshotline" },
            { name: "Telefonberatung247", value: "aragPermissions/Telefonberatung247" },
            { name: "Vertragscheck", value: "aragPermissions/Vertragscheck" },
            { name: "PruefungAnstellungsvertrag", value: "aragPermissions/PruefungAnstellungsvertrag" },
        ] },
    { name: "Roland", value: "rolandPermissions", childPermissions: [ { name: "Telefonberatung", value: "rolandPermissions/Telefonberatung" } ] },
];
