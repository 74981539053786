import Vue from 'vue'
import Router from 'vue-router'
import KeyCloakService from "@/plugins/keycloak";
import reactiveStorage from "@/plugins/reactiveStorage";

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'Home',
            meta: {
                authRequired: true,
                isPermitted: reactiveStorage.user.roles.User,
            },
            component: () => import('../views/Home')
        },
        {
            path: '/MPU/mpu',
            name: 'MPU',
            meta: {
                authRequired: true,
                isPermitted: reactiveStorage.user.roles.User,
            },
            component: () => import('../views/mpu/MPUView')
        },
        {
            path: '/form/mpu',
            name: 'MPUForm',
            meta: {
                authRequired: true,
                isPermitted: reactiveStorage.user.roles.User,
            },
            component: () => import('../views/mpu/MPUFormView')
        },
        {
            path: '/advocardPrivate/Dokumentencheck',
            name: 'documents',
            meta: {
                authRequired: true,
                isPermitted: reactiveStorage.user.roles.User,
            },
            component: () => import('../views/insurance/advocard/DokumentencheckView')
        },
        {
            path: '/advocardPrivate/Mediation',
            name: 'mediations',
            meta: {
                authRequired: true,
                isPermitted: reactiveStorage.user.roles.User,
            },
            component: () => import('../views/insurance/advocard/MediationView')
        },
        {
            path: '/advocardPrivate/Coaching',
            name: 'coaching',
            meta: {
                authRequired: true,
                isPermitted: reactiveStorage.user.roles.User,
            },
            component: () => import('../views/insurance/advocard/CoachingView')
        },
        {
            path: '/advocardPrivate/Fachberatung',
            name: 'fachberatung',
            meta: {
                authRequired: true,
                isPermitted: reactiveStorage.user.roles.User,
            },
            component: () => import('../views/insurance/advocard/FachberatungView.vue')
        },
        {
            path: '/about',
            name: 'About',
            meta: {
                authRequired: false,
            },
            component: () => import('../views/unauthorized/About')
        },
        {
            path: '/privacy',
            name: 'Privacy',
            meta: {
                authRequired: false,
            },
            component: () => import('../views/unauthorized/Privacy')
        },
        {
            path: '/advocardPrivate',
            name: 'Advocard Private',
            meta: {
                authRequired: true,
                isPermitted: reactiveStorage.user.roles.User,
            },
            component: () => import('../views/insurance/advocard/forms/AdvocardPrivate')
        },
        {
            path: '/advocardInternational',
            name: 'Advocard International',
            meta: {
                authRequired: true,
                isPermitted: reactiveStorage.user.roles.User,
            },
            component: () => import('../views/insurance/advocard/forms/AdvocardInternational')
        },
        {
            path: '/advocardBusiness',
            name: 'Advocard Business',
            meta: {
                authRequired: true,
                isPermitted: reactiveStorage.user.roles.User,
            },
            component: () => import('../views/insurance/advocard/forms/AdvocardBusiness')
        },
        {
            path: '/arag',
            name: 'Arag',
            meta: {
                authRequired: true,
                isPermitted: reactiveStorage.user.roles.User,
            },
            component: () => import('../views/insurance/arag/forms/TelefonberatungForm')
        },
        {
            path: '/arag/ManagerRechtsschutz',
            name: 'AragManagerRechtsschutz',
            meta: {
                authRequired: true,
                isPermitted: reactiveStorage.user.roles.User,
            },
            component: () => import('../views/insurance/arag/ManagerRechtsschutzView')
        },
        {
            path: '/arag/Dokumentenpruefung',
            name: 'AragDokumentenpruefung',
            meta: {
                authRequired: true,
                isPermitted: reactiveStorage.user.roles.User,
            },
            component: () => import('../views/insurance/arag/ErstberatungMitDokumentenpruefungView')
        },
        {
            path: '/arag/PruefungAnstellungsvertrag',
            name: 'AragPruefungAnstellungsvertrag',
            meta: {
                authRequired: true,
                isPermitted: reactiveStorage.user.roles.User,
            },
            component: () => import('../views/insurance/arag/PruefungAnstellungsvertragView')
        },
        {
            path: '/aragStrafrechtshotline',
            name: 'AragStrafrechtshotline',
            meta: {
                authRequired: true,
                isPermitted: reactiveStorage.user.roles.User,
            },
            component: () => import('../views/insurance/arag/forms/StrafrechtshotlineForm')
        },
        {
            path: '/aragPruefungAnstellungsvertrag',
            name: 'AragPruefungAnstellungsvertragForm',
            meta: {
                authRequired: true,
                isPermitted: reactiveStorage.user.roles.User,
            },
            component: () => import('../views/insurance/arag/forms/PruefungAnstellungsvertragForm')
        },
        {
            path: '/aragErstberatung',
            name: 'AragDokumentenpruefungForm',
            meta: {
                authRequired: true,
                isPermitted: reactiveStorage.user.roles.User,
            },
            component: () => import('../views/insurance/arag/forms/ErstberatungMitDokumentenpruefungForm')
        },
        {
            path: '/roland',
            name: 'Roland',
            meta: {
                authRequired: true,
                isPermitted: reactiveStorage.user.roles.User,
            },
            component: () => import('../views/insurance/Roland')
        },
        {
            path: '/aragVersicherungspruefung',
            name: 'AragVersicherungspruefung',
            meta: {
                authRequired: true,
                isPermitted: reactiveStorage.user.roles.User,
            },
            component: () => import('../views/insurance/arag/forms/ErstberatungVersicherungspruefungForm')
        },
        {
            path: '/arag/ErstberatungVersicherungspruefung',
            name: 'AragErstberatungVersicherungspruefung',
            meta: {
                authRequired: true,
                isPermitted: reactiveStorage.user.roles.User,
            },
            component: () => import('../views/insurance/arag/ErstberatungVersicherungspruefungView')
        },
        {
            path: '/export',
            name: 'Export',
            meta: {
                authRequired: true,
                isPermitted: reactiveStorage.user.roles.User,
            },
            component: () => import('../views/Export')
        },
        {
            path: '/statistics',
            name: 'Statistics',
            meta: {
                authRequired: true,
                isPermitted: reactiveStorage.user.roles.Admin || reactiveStorage.user.roles.Moderator,
            },
            component: () => import('../views/admin/StatisticsView')
        },
        {
            path: '/bill',
            name: 'Bill',
            meta: {
                authRequired: true,
                isPermitted: reactiveStorage.user.roles.Admin,
            },
            component: () => import('../views/Bill')
        },
        {
            path: '/calendar',
            name: 'Calendar',
            meta: {
                authRequired: true,
                isPermitted: reactiveStorage.user.roles.User,
            },
            component: () => import('../views/Calendar')
        },
        {
            path: '/permissions',
            name: 'Permissions',
            meta: {
                authRequired: true,
                isPermitted: reactiveStorage.user.roles.Admin,
            },
            component: () => import('../views/admin/AdminInterface')
        },
        {
            path: '/arag/OnlineBeratung',
            name: 'OnlineBeratung',
            meta: {
                authRequired: true,
                isPermitted: reactiveStorage.user.roles.User,
            },
            component: () => import('../views/insurance/arag/OnlineBeratungView')
        },
        {
            path: '/arag/Vertragscheck',
            name: 'Vertragscheck',
            meta: {
                authRequired: true,
                isPermitted: reactiveStorage.user.roles.User,
            },
            component: () => import('../views/insurance/arag/VertragscheckView')
        },
        {
            path: '/unassignedMails',
            name: 'UnassignedMails',
            meta: {
                authRequired: true,
                isPermitted: reactiveStorage.user.roles.User,
            },
            component: () => import('../views/UnassignedMails')
        },
        {
            path: '/admin/aragVertragscheck',
            name: 'AragVertragscheck',
            meta: {
                authRequired: true,
                isPermitted: reactiveStorage.user.roles.Admin,
            },
            component: () => import('../views/insurance/admin/AragVertragscheck')
        },
        {
            path: '/admin/aragManagerRechtsschutz',
            name: 'AdminAragManagerRechtsschutz',
            meta: {
                authRequired: true,
                isPermitted: reactiveStorage.user.roles.Admin,
            },
            component: () => import('../views/insurance/admin/AragManagerRechtsschutz')
        },
        {
            path: '/admin/aragOnlineBeratung',
            name: 'AdminAragOnlineBeratung',
            meta: {
                authRequired: true,
                isPermitted: reactiveStorage.user.roles.Admin,
            },
            component: () => import('../views/insurance/admin/AragOnlineBeratung')
        },
        {
            path: '/upload/documents',
            name: 'Upload',
            meta: {
                authRequired: false,
            },
            component: () => import('../views/unauthorized/LegalUpload')
        },
        /* can be removed later - temp for stagrecht.de redirect */
        {
            path: '/dokumentencheck',
            name: 'OldUploadLink',
            meta: {
                authRequired: false,
            },
            component: () => import('../views/unauthorized/LegalUpload')
        },
    ],
});

router.beforeEach(async (to, from, next) => {
    if (to.meta.authRequired) {
        if (KeyCloakService.GetIsAuthenticated() === undefined) {
            // console.log("KeyCloakService.GetIsAuthenticated() === undefined");
            await KeyCloakService.CallLogin();
            next();
            } else {
            next();
        }
    } else next();
})

export default router
